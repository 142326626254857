import { forwardRef } from "react";
import { IconProps } from "../shared";
import { Icon } from "../icon";
export const IconCheckCircle = forwardRef<HTMLElement, IconProps>(
    (props, ref) => (
        <Icon ref={ref} {...props}>
            <path
                fill="#111928"
                fillRule="evenodd"
                d="M6.343 17.657A8 8 0 1 1 17.657 6.343 8 8 0 0 1 6.343 17.657m4.4-1.414L7 12.53l1.415-1.414 2.328 2.297 4.95-4.95 1.414 1.415z"
                clipRule="evenodd"
            />
        </Icon>
    )
);
IconCheckCircle.displayName = "IconCheckCircle";
